"use client";

import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { PlatformsIcon } from "~/components/non-editable/core";
import { useRouter, usePathname } from "next/navigation";
import { OrderComponentProps } from "./props";
import styles from "./order.module.scss";
import { getSortOptions } from "~/utils";
import { Options } from "~/types";
import { useState } from "react";
import { WebsitePreference, WebsitePreferenceType } from "../../../mock";
import slugify from "slugify";

export default function OrderComponent({ props }: OrderComponentProps) {
  const [open, setOpen] = useState<boolean>(false);
  const sortOptions = getSortOptions(props.options);
  const { push } = useRouter();
  const pathname = usePathname();

  const inventorySlugPreference =
    ((props.data.site.preferences as WebsitePreference[]) ?? []).find(
      (preference) =>
        preference.type === WebsitePreferenceType.inventoryPathName
    )?.value ?? "estoque";

  const localSeoPreference =
    ((props.data.site.preferences as WebsitePreference[]) ?? [])
      .find(
        (preference) => preference.type === WebsitePreferenceType.localSeoSlug
      )
      ?.value.split(",")[0] ?? "brasil";

  const inventoryType = props?.data?.site?.inventoryType ?? "/carros";

  const details = extractVehicleDetailsFromPathname(pathname ?? "");

  async function handleSort(sort: string) {
    let updatedPath = pathname ?? "";

    if (updatedPath === "/") {
      updatedPath = `/${slugify(inventorySlugPreference, {
        lower: true,
      })}${inventoryType}/${slugify(localSeoPreference, {
        lower: true,
      })}/ordenar=${sort}`;
    } else {
      if (updatedPath.includes("ordenar=")) {
        updatedPath = updatedPath.replace(/ordenar=[^&\/]*/, `ordenar=${sort}`);
      } else {
        if (updatedPath.includes("&") || updatedPath.includes("=")) {
          updatedPath = `${updatedPath}&ordenar=${sort}`;
        } else {
          updatedPath = `${updatedPath}/ordenar=${sort}`;
        }
      }
    }

    push(updatedPath, { scroll: true });
  }

  function handleCollapse() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  // function handleClose() {
  //   setOpen(false);
  // }

  // const ref = useClickDetectOutside(handleClose);

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={handleCollapse}>
        Ordenar
        <PlatformsIcon
          className={`${styles.icon} ${open ? styles["icon--open"] : ""}`}
          icon={open ? "tabler:chevron-up" : "tabler:chevron-down"}
        />
      </div>

      {open && (
        <div
          // ref={ref}
          className={`${styles["order-container"]} order-container`}
        >
          {sortOptions.map((option: Options, idx) => (
            <a
              key={idx}
              onClick={() => handleSort(option.code as string)}
              className={`${styles.item} item ${
                details?.filter?.sortBy[0] == option?.code
                  ? styles["item--selected"]
                  : ""
              }`}
            >
              {option.name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
